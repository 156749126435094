import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 3</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Marketing companies study how to influence people to buy their products or buy into their causes. You have just learned about some of them. These strategies can all be used for good reasons—and they can be used to mislead people. An ad may use more than one of these strategies.</p>

              <p>Click on the link below to see an ad for the lemonade diet cleanse.</p>

              <Unordered>
                <li>Can you identify any persuasion strategies in the ad?</li>
                <li>What information on the site supports the persuasion strategy or strategies you identified?</li>
              </Unordered>

              <p>Record your answers to these questions in your science notebook.</p>

              <Button
                action="secondary"
                color="purple"
                href="/article/lemonade-diet"
                icon="external"
                title="Lemonade diet cleanse website"
              >
                Lemonade diet cleanse website
              </Button>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students look at a website to see if they can identify any persuasion strategies used. They may notice the authority strategy with the quote by a doctor. Alternatively, they may notice the claim strategy with inclusion of a statistic from the CDC and the results of a Scandinavian study (although the validity of these statistics is questionable).</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
